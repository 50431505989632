import Image from "next/legacy/image";
import clsx from "clsx";
import React from "react";
export type ComplianceLogoVariant = keyof typeof variants;
interface Props {
  className?: string;
  centeredHack?: boolean; // Temporary hack to make the logos feel centered on mobile
  variant?: ComplianceLogoVariant;
}
const variants = {
  regular: "",
  dark: "filter-to-beige",
  plum: "filter-to-plum",
  beige: "filter-to-beige",
  white: "filter-to-white"
};
export const Compliance: React.FC<Props> = ({
  className,
  centeredHack,
  variant = "regular"
}) => {
  return <div className={clsx("grid auto-cols-min grid-flow-col items-center gap-3", {
    "pl-6 lg:pl-0": centeredHack
  }, variants[variant], className)} data-sentry-component="Compliance" data-sentry-source-file="Compliance.tsx">
      <Image src="/images/compliance/soc.svg" width={52} height={52} layout="fixed" alt="SOC 2 Type II badge" data-sentry-element="Image" data-sentry-source-file="Compliance.tsx" />
      <a className="ml-1 flex items-center justify-center" href="https://www.bbb.org/us/ny/new-york/profile/bereavement-services/empathy-0121-87158346" target="_blank" rel="noopener noreferrer nofollow">
        <Image src="/images/compliance/bbb.svg" width={86} height={31} layout="fixed" alt="Better Business Bureau Badge" data-sentry-element="Image" data-sentry-source-file="Compliance.tsx" />
      </a>
      <Image src="/images/compliance/secure-ssl.svg" width={75} height={31} layout="fixed" alt="Secure SSL Badge" data-sentry-element="Image" data-sentry-source-file="Compliance.tsx" />
    </div>;
};