export const CONTENT_CATEGORY_TEXT_ME_THE_APP = "Click - Text me the app";
export const CONTENT_CATEGORY_DOWNLOAD_BADGE_CLICKED =
  "Click - Download from Store";

export const CONTENT_NAME_AUDIO = "Audio";
export const CONTENT_NAME_GLOSSARY = "Glossary";
export const CONTENT_NAME_PRODUCT_HEADER = "Product";
export const CONTENT_NAME_PRODUCT_LISTEN = "Listen on the app";
export const CONTENT_NAME_APP_STORE = "App Store";
export const CONTENT_NAME_GOOGLE_PLAY = "Google Play";
