import clsx from "clsx";
import React from "react";
import * as fbq from "../lib/fbpixel";
import { CONTENT_CATEGORY_DOWNLOAD_BADGE_CLICKED, CONTENT_NAME_APP_STORE, CONTENT_NAME_GOOGLE_PLAY } from "../lib/fbpixel/leadParams";
import Image from "next/legacy/image";
interface Props {
  variant?: keyof typeof VARIANTS;
  campaignBannerId?: string;
  className?: string;
}
const androidLink = "https://play.google.com/store/apps/details?id=com.empathy.companion&gl=US&pli=1";
const iosLink = "https://apps.apple.com/us/app/id1536395192/id1536395192";
const VARIANTS = {
  light: {
    width: 120,
    height: 40,
    ios: "/images/badge-app-store@2x.png",
    android: "/images/badge-play-store@2x.png"
  },
  dark: {
    width: 135,
    height: 40,
    ios: "/images/badge-app-store-dark@2x.png",
    android: "/images/badge-play-store-dark@2x.png"
  },
  empathy: {
    width: 127,
    height: 46,
    ios: "/images/download/appstore-badge.svg",
    android: "/images/download/playstore-badge.svg"
  }
};
export const DownloadAppBadges = ({
  variant = "light",
  campaignBannerId,
  className
}: Props) => {
  const variantStyle = VARIANTS[variant];
  return <div className={clsx("flex flex-row items-center", className)} data-sentry-component="DownloadAppBadges" data-sentry-source-file="DownloadAppBadges.tsx">
      <a id={`${campaignBannerId}-apple-store`} href={iosLink} target="_blank" rel="noreferrer noopener nofollow" className="mr-1 flex">
        <Image id="download_app_on_apple_store" src={variantStyle.ios} alt="Download on the App Store" width={variantStyle.width} height={variantStyle.height} quality={100} onClick={() => fbq.leadEvent({
        content_category: CONTENT_CATEGORY_DOWNLOAD_BADGE_CLICKED,
        content_name: CONTENT_NAME_APP_STORE
      })} data-sentry-element="Image" data-sentry-source-file="DownloadAppBadges.tsx" />
      </a>
      <a href={androidLink} target="_blank" rel="noreferrer noopener nofollow" id={`${campaignBannerId}-google-play`} className="flex">
        <Image width={variantStyle.width} height={variantStyle.height} id="download_app_on_google_play" src={variantStyle.android} alt="Get it on Google Play" quality={100} onClick={() => fbq.leadEvent({
        content_category: CONTENT_CATEGORY_DOWNLOAD_BADGE_CLICKED,
        content_name: CONTENT_NAME_GOOGLE_PLAY
      })} data-sentry-element="Image" data-sentry-source-file="DownloadAppBadges.tsx" />
      </a>
    </div>;
};